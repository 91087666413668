.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    text-align: center;
    color: #333;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .input {
    padding: 8px;
    font-size: 1rem;
  }
  
  .button {
    background-color: #7AB0AB;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  
  .loading {
    color: #7AB0AB;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .error {
    color: red;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .result {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .result h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .result p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  body {
    margin: 0;
    text-align: center;
    display: flex; /* Activa el modo Flexbox */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la ventana */
  }
  

  form {
    padding-top: 30px;
  }

  .titleResult {
    padding-top: 59px;
  }